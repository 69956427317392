import styled from "@emotion/styled";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import { Content } from "src/atoms/Content";
import BaseButton from "src/atoms/Button/BaseButton";

const Wrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space[5]}px;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const LetterListWrapper = styled.nav`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
  border-top: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
  width: 100%;
`;

const LetterList = styled(Content)`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
  justify-content: space-between;
  margin: 32px auto;
  padding: 0 11px;
`;

const LetterItem = styled(BaseButton)<{ ["aria-disabled"]?: boolean }>`
  color: ${(props) =>
    props["aria-disabled"]
      ? props.theme.colors.tone.silver
      : props.theme.colors.tone.black};
  cursor: ${(props) => !props["aria-disabled"] && "pointer"};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: bold;
  letter-spacing: 1.6px;
  line-height: 1.33;
  margin: 0 9px;
  text-transform: uppercase;
  border: none;
  background: none;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 70px auto;
`;

const RowContent = styled.section`
  margin-top: ${({ theme }) => theme.space[5]}px;
`;

const MainLetter = styled.h2`
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  font-weight: bold;
  letter-spacing: 2.4px;
  line-height: 1.25;
  text-transform: uppercase;
`;

const Row = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 238px);
  margin-top: 8px;
  word-break: break-all;
`;

const ItemContent = styled.a`
  display: flex;
  flex-direction: column;
  height: 44px;
  &:hover {
    span:first-of-type {
      font-weight: 600;
      letter-spacing: 0.2px;
    }

    span:last-of-type {
      letter-spacing: 0.4px;
    }
  }
`;

const Item = styled.span`
  color: ${({ theme }) => theme.colors.tone.black};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  letter-spacing: 0.4px;
  line-height: 1.5;
  position: relative;
  button {
    top: -50%;
    transform: translate(0, 0%);
  }
`;

export {
  Wrapper,
  LetterListWrapper,
  LetterList,
  LetterItem,
  StyledLoadingSpinner,
  RowContent,
  MainLetter,
  Row,
  ItemContent,
  Item,
};

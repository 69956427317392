import { useEffect } from "react";

export const useAsync = <T>(
  asyncFn: () => Promise<T>,
  onSuccess: (data: T) => void,
  delay?: number
): void => {
  useEffect(() => {
    let isActive = true;
    if (delay) {
      setTimeout(() => {
        if (isActive) {
          void asyncFn().then(onSuccess);
        }
      }, delay);
    } else {
      void asyncFn().then((data) => {
        if (isActive) {
          onSuccess(data);
        }
      });
    }
    return () => {
      isActive = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

import { FC, useState, useEffect } from "react";
import { t, Trans } from "@lingui/macro";
import Link from "next/link";
import { Locale } from "lib/Intl";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { formatName } from "src/utils/formatName";
import { AlgoliaSearchBrand } from "src/types/Algolia";
import { allBrandsSorted } from "src/organisms/AllBrandsSearch/sortBrands";
import { ToggleWishListBrandButton } from "./ToggleWishListBrandButton";
import {
  Wrapper,
  InputWrapper,
  Input,
  StyledSearchIcon,
  Content,
  StyledLoadingSpinner,
  ContentItem,
  Item,
  NoResults,
} from "./SearchMobileView.styled";
import AllBrandsLabel from "./AllBrandsLabel";
import { useLingui } from "@lingui/react";

interface Props {
  brands: AlgoliaSearchBrand[] | undefined | null;
  loading: boolean;
  genderId: string;
  locale: Locale;
}

const SearchMobileView: FC<Props> = ({ brands, loading, genderId, locale }) => {
  const { i18n } = useLingui();
  const sortedBrands = (brands &&
    allBrandsSorted(brands)) as AlgoliaSearchBrand[];
  const [searchVal, setSearchVal] = useState("");
  const [brandsSortedMobile, setBrandsSortedMobile] = useState(sortedBrands);

  useEffect(() => {
    if (searchVal) {
      const formattedSearchVal = searchVal.toLowerCase().replace(/ /g, "");
      const searchResultsMobile = sortedBrands.filter((el) =>
        formatName(el.name)
          .toLowerCase()
          .replace(/ /g, "")
          .includes(formattedSearchVal)
      );
      return setBrandsSortedMobile(searchResultsMobile);
    } else if (sortedBrands) {
      return setBrandsSortedMobile(sortedBrands);
    }
  }, [searchVal, sortedBrands]);

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          type="text"
          placeholder={t(i18n)`Search A-Z brands`}
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
        />
        {!searchVal && <StyledSearchIcon />}
      </InputWrapper>
      <Content>
        {loading && <StyledLoadingSpinner stroke={"#000"} />}
        {!loading &&
          brandsSortedMobile?.map((brand) => (
            <Link
              key={brand.objectID}
              href="/[slug]/[brandSlug]/[genderId]"
              as={`${getI18nRoute({
                route: `/brands`,
                locale,
              })}/${brand.slug}${getI18nRoute({
                route: `/${genderId}`,
                locale,
              })}`}
              passHref
              legacyBehavior
            >
              <ContentItem key={brand.objectID}>
                <Item>
                  {formatName(brand.name)}
                  <ToggleWishListBrandButton
                    brandId={brand.objectID}
                    brandName={brand.name}
                  />
                </Item>
                <AllBrandsLabel brand={brand} />
              </ContentItem>
            </Link>
          ))}
        {!loading && brandsSortedMobile?.length === 0 && (
          <NoResults>
            <Trans>No brands found</Trans>
          </NoResults>
        )}
      </Content>
    </Wrapper>
  );
};

export default SearchMobileView;

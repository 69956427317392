import styled from "@emotion/styled";
import { Search as SearchIcon } from "src/icons/Search";
import { LoadingSpinner } from "src/atoms/LoadingSpinner";

const Wrapper = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: block;
  }
`;

const InputWrapper = styled.div`
  box-sizing: content-box;
  display: flex;
  margin: ${({ theme }) => theme.space[3]};
  position: relative;
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.tone.white};
  border: solid 1px ${({ theme }) => theme.colors.tone.silver};
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  letter-spacing: 0.4px;
  line-height: 1.5;
  padding: 12px 16px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.tone.anthraciteLight};
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  bottom: 0;
  height: 24px;
  margin: auto 0;
  position: absolute;
  right: 12px;
  stroke: ${({ theme }) => theme.colors.tone.anthraciteLight};
  top: 0;
  width: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px;

  > div:last-of-type {
    border-bottom: none;
  }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 70px auto;
`;

const ContentItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.platinumLight};
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  padding: 0 16px;
  position: relative;
`;

const Item = styled.span`
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  letter-spacing: 0.4px;
  line-height: 1.5;
`;

const NoResults = styled.span`
  padding: 30px 0;
  text-align: center;
  width: 100%;
`;

export {
  Wrapper,
  InputWrapper,
  Input,
  StyledSearchIcon,
  Content,
  StyledLoadingSpinner,
  ContentItem,
  Item,
  NoResults,
};

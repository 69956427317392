import { FC, useContext, useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import styled from "@emotion/styled";
import { AppCtx } from "src/contexts/app.context";
import SearchDesktopView from "./components/SearchDesktopView";
import SearchMovileView from "./components/SearchMobileView";
import { localeFix } from "src/utils/genderLocale/genderLocale";
import { useAlgoliaBrands } from "./useAlgoliaBrands";
import { PageHead } from "src/molecules/PageHead";
import { useLingui } from "@lingui/react";
import { useDispatch } from "react-redux";
import { setGender } from "src/modules/page";

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.tone.black};
  font-size: 32px;
  letter-spacing: 2.4px;
  line-height: 1.25;
  margin: 32px auto;
  max-width: 1264px;
  padding: 0 20px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 24px;
    letter-spacing: 1.6px;
    line-height: 1.33;
    margin: 16px auto;
    text-align: center;
  }
`;

interface Props {
  genderId: string;
}

const AllBrandsSearch: FC<Props> = ({ genderId }) => {
  const localeGenderId = localeFix({ gender: genderId });
  const { locale } = useContext(AppCtx);
  const reduxDispatch = useDispatch();
  const { brands, loading } = useAlgoliaBrands({
    locale,
    gender: localeGenderId,
  });

  const { i18n } = useLingui();
  const description = t(
    i18n
  )`Discover all the brands for {genderId} at Otrium. Shop top-brand clothing with discounts up to 70%. Find your favorite brands at great prices!`;

  useEffect(() => {
    reduxDispatch(setGender(localeGenderId));
  }, [localeGenderId, reduxDispatch]);

  return (
    <main>
      <PageHead title={t(i18n)`Brands A to Z`} description={description} />
      <header>
        <Title>
          <Trans>Brands A to Z</Trans>
        </Title>
      </header>
      <SearchDesktopView
        brands={brands}
        loading={loading}
        genderId={genderId}
        locale={locale}
      />
      <SearchMovileView
        brands={brands}
        loading={loading}
        genderId={genderId}
        locale={locale}
      />
    </main>
  );
};

export default AllBrandsSearch;

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AlgoliaSearchBrand } from "src/types/Algolia";

const allBrandsSorted = (brands: AlgoliaSearchBrand[]): any => {
  const sorted = brands?.sort((a, b) => {
    const first = a.name.toLowerCase();
    const second = b.name.toLowerCase();
    return first === second ? 0 : first < second ? -1 : 1;
  });

  return sorted;
};

interface AlphabetLettersProps {
  [key: string]: {
    [key: string]: any;
  };
}

const composeBrandsByFirstLetter = (brands: AlgoliaSearchBrand[]): any => {
  const alphabetLetters = {
    "#": [],
    a: [],
    b: [],
    c: [],
    d: [],
    e: [],
    f: [],
    g: [],
    h: [],
    i: [],
    j: [],
    k: [],
    l: [],
    m: [],
    n: [],
    o: [],
    p: [],
    q: [],
    r: [],
    s: [],
    t: [],
    u: [],
    v: [],
    w: [],
    x: [],
    y: [],
    z: [],
  } as AlphabetLettersProps;

  brands?.forEach((brand) => {
    const firstLetter = brand.name.toLowerCase().charAt(0);

    if (alphabetLetters[firstLetter]) {
      // brands that start with a letter
      alphabetLetters[firstLetter].push(brand);
    } else {
      // brands that start with number or special character
      alphabetLetters["#"].push(brand);
    }
  });

  // sort each array alphabetically
  Object.keys(alphabetLetters).forEach((letter) => {
    alphabetLetters[letter].sort(
      (a: AlgoliaSearchBrand, b: AlgoliaSearchBrand) => {
        const first = a.name.toLowerCase();
        const second = b.name.toLowerCase();
        return first === second ? 0 : first < second ? -1 : 1;
      }
    );
  });

  return alphabetLetters;
};

export { allBrandsSorted, composeBrandsByFirstLetter };

import { FC } from "react";
import { t } from "@lingui/macro";
import { useTheme, Theme } from "@emotion/react";
import { Flex } from "@otrium/core";
import { Label } from "src/atoms/Label";
import { AlgoliaSearchBrand } from "src/types/Algolia";
import { useLingui } from "@lingui/react";

interface Props {
  brand: AlgoliaSearchBrand;
  isLoggedIn?: boolean;
}

const AllBrandsLabel: FC<Props> = ({ brand, isLoggedIn }) => {
  const { i18n } = useLingui();
  const theme: Theme = useTheme();

  if (brand.designer_brand) {
    return (
      <Flex ml={isLoggedIn ? 5 : 0} pl={isLoggedIn ? 2 : 0}>
        <Label
          type="designer"
          value={t(i18n)`Designer`}
          color={theme.colors.primary.gold}
          showBackground={false}
        />
      </Flex>
    );
  }

  if (brand.good_on_you_info) {
    return (
      <Flex ml={isLoggedIn ? 5 : 0} pl={isLoggedIn ? 2 : 0} verticalAlign="top">
        <Label
          type="goodOnYou"
          value={t(i18n)`Conscious`}
          color={theme.colors.primary.forestGreen}
          showBackground={false}
        />
      </Flex>
    );
  }

  return null;
};

export default AllBrandsLabel;

import { FC, useCallback, useMemo } from "react";
import { ToggleWishListButton } from "src/molecules/ToggleWishListButton";
import { useAddToWishList } from "src/hooks/useAddToWishlist";
import { useDeleteWishlistItem } from "src/hooks/deleteWishlistItem";
import { useSegment } from "src/hooks/useSegment";
import { WishListIconType } from "src/molecules/ToggleWishListButton/ToggleWishListButton";
import { WishlistItemType } from "src/types/graphql.d";
import { useToggleWishlistBrandItem } from "src/hooks/toggleWishlistItem";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";

interface ToggleWishListBrandProps {
  brandId: string;
  brandName: string;
}
const ToggleWishListBrandButton: FC<ToggleWishListBrandProps> = ({
  brandId,
  brandName,
}) => {
  const { addedToWishlist } = useToggleWishlistBrandItem(brandId);
  const isLoggedIn = useIsLoggedIn();
  const { addToWishlist, loading: addLoading } = useAddToWishList({
    refetchQueries: ["Brands", "userWishlistMetaData"],
  });

  const { deleteWishListItem, loading: deleteLoading } = useDeleteWishlistItem({
    refetchQueries: ["Brands", "userWishlistMetaData"],
  });

  const { brandFavourited, brandUnfavourited, brandFavouritedWhenLoggedOut } =
    useSegment();

  const brandWishlistTracking = useMemo(() => {
    return { brandName, brandId };
  }, [brandName, brandId]);

  const onAddToWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      if (isLoggedIn) {
        await addToWishlist(itemType, itemId);
        void brandFavourited(brandWishlistTracking);
        return;
      }
      void brandFavouritedWhenLoggedOut(brandWishlistTracking);
    },
    [
      addToWishlist,
      brandFavourited,
      brandWishlistTracking,
      brandFavouritedWhenLoggedOut,
      isLoggedIn,
    ]
  );

  const onRemoveFromWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      await deleteWishListItem(itemType, itemId, {});
      void brandUnfavourited(brandWishlistTracking);
    },
    [brandUnfavourited, brandWishlistTracking, deleteWishListItem]
  );

  return (
    <ToggleWishListButton
      isAddedToWishList={addedToWishlist}
      itemType={WishlistItemType.Brand}
      itemId={brandId}
      loading={addLoading || deleteLoading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onAddToWishList={onAddToWishList}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onRemoveWishList={onRemoveFromWishList}
      iconType={WishListIconType.HEART_PAGE}
    />
  );
};

export { ToggleWishListBrandButton };

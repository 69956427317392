import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Text } from "@otrium/atoms";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import Link from "next/link";
import { Locale } from "lib/Intl";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { formatName } from "src/utils/formatName";
import { composeBrandsByFirstLetter } from "../sortBrands";
import { ToggleWishListBrandButton } from "./ToggleWishListBrandButton";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import {
  Wrapper,
  LetterListWrapper,
  LetterList,
  LetterItem,
  StyledLoadingSpinner,
  RowContent,
  MainLetter,
  Row,
  ItemContent,
  Item,
} from "./SearchDesktopView.styled";
import { Content } from "src/atoms/Content";
import AllBrandsLabel from "./AllBrandsLabel";
import { Sticky } from "src/atoms/Sticky";
import { AlgoliaSearchBrand } from "src/types/Algolia";

interface Props {
  brands: AlgoliaSearchBrand[] | undefined | null;
  loading: boolean;
  genderId: string;
  locale: Locale;
}

interface SortedBrandsProps {
  [key: string]: {
    [key: string]: any;
  };
}

const StickyLetterList = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { isDesktop } = useBreakpoints();
  const initialState = useMemo(() => ({ container: {}, inner: {} }), []);
  const [styles, setStyles] = useState(initialState);
  const onChangeMode = useCallback(
    (mode: any) => {
      if (mode) {
        setStyles({
          inner: {
            maxWidth: "100%",
          },
          container: {
            background: "white",
          },
        });
      } else {
        setStyles(initialState);
      }
    },
    [setStyles, initialState]
  );

  const { container: containerStyles, inner: innerStyles } = styles;
  const contentRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState<number | null>(null);
  useEffect(() => {
    const offset = contentRef?.current?.getBoundingClientRect()?.top;
    if (offset) {
      setOffsetTop(offset);
    }
  }, [contentRef, offsetTop, setOffsetTop]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isDesktop ? (
    <Box display={["none", "none", "block", "block"]}>
      {offsetTop !== null && offsetTop > 0 && scrollPosition >= offsetTop ? (
        <Sticky
          offsetTop={-offsetTop + 185}
          onChange={onChangeMode}
          top={63}
          boxshadowMode={"down"}
        >
          <Box sx={{ ...containerStyles }}>
            <Box sx={{ ...innerStyles }}>{children}</Box>
          </Box>
        </Sticky>
      ) : (
        <div ref={contentRef}>{children}</div>
      )}
    </Box>
  ) : (
    <>{children}</>
  );
};

const SearchDesktopView: FC<Props> = ({
  brands,
  loading,
  genderId,
  locale,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const sortedBrands = (brands &&
    composeBrandsByFirstLetter(brands)) as SortedBrandsProps;

  return (
    <Wrapper>
      {loading && <StyledLoadingSpinner stroke={"#000"} />}
      {!loading && sortedBrands && (
        <>
          <LetterListWrapper>
            <StickyLetterList>
              <LetterList data-testid="search-letters-list-all-brands" as="ul">
                {Object.keys(sortedBrands).map((letter) => (
                  <li key={letter}>
                    <LetterItem
                      data-testid={`search-letter-${letter}`}
                      aria-disabled={!sortedBrands[letter].length}
                      onClick={() => {
                        document
                          ?.getElementById(`search-brand-letter-${letter}`)
                          ?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                          });
                      }}
                    >
                      {letter}
                    </LetterItem>
                  </li>
                ))}
              </LetterList>
            </StickyLetterList>
          </LetterListWrapper>
          <Content data-testid="search-results-all-brands">
            {Object.keys(sortedBrands).map((letter) => {
              const letterBrands = sortedBrands[letter] as AlgoliaSearchBrand[];
              if (!letterBrands.length) return null;

              return (
                <RowContent key={letter}>
                  <MainLetter id={`search-brand-letter-${letter}`}>
                    {letter}
                  </MainLetter>
                  <Row>
                    {letterBrands.map((brand) => (
                      <Link
                        href="/[slug]/[brandSlug]/[genderId]"
                        as={`${getI18nRoute({
                          route: `/brands`,
                          locale,
                        })}/${brand.slug}${getI18nRoute({
                          route: `/${genderId}`,
                          locale,
                        })}`}
                        passHref
                        key={brand.objectID}
                        legacyBehavior
                      >
                        <ItemContent>
                          <Item>
                            <Flex>
                              {isLoggedIn && (
                                <Flex
                                  ml={5}
                                  pl={2}
                                  sx={{
                                    position: "relative",
                                    height: "100%",
                                    top: "0",
                                    marginTop: "-14px",
                                  }}
                                >
                                  <ToggleWishListBrandButton
                                    brandId={brand.objectID}
                                    brandName={brand.name}
                                  />
                                </Flex>
                              )}
                              <Flex>
                                <Text
                                  as="p"
                                  sx={{
                                    wordBreak: "normal",
                                  }}
                                >
                                  {formatName(brand.name)}
                                </Text>
                              </Flex>
                            </Flex>
                          </Item>
                          <AllBrandsLabel
                            brand={brand}
                            isLoggedIn={isLoggedIn}
                          />
                        </ItemContent>
                      </Link>
                    ))}
                  </Row>
                </RowContent>
              );
            })}
          </Content>
        </>
      )}
    </Wrapper>
  );
};

export default SearchDesktopView;

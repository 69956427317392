import { useContext, useState } from "react";
import { MultipleQueriesResponse } from "@algolia/client-search";
import { AlgoliaSearchBrand } from "src/types/Algolia";
import Algolia from "lib/algolia";
import { AppCtx } from "src/contexts/app.context";
import { useAsync } from "src/hooks/useAsync";
import { filter } from "lodash-es";
import { useSession } from "next-auth/client";

interface AlgoliaSearchData {
  brands: AlgoliaSearchBrand[] | null;
  loading: boolean;
}

export const useAlgoliaBrands = ({
  locale,
  gender,
  brandSaleType = "REGULAR",
}: {
  locale: string;
  gender: string;
  brandSaleType?: "REGULAR" | "FLASH_SALE";
}): AlgoliaSearchData => {
  const [session] = useSession();
  const { isGhostDomain } = useContext(AppCtx);
  const [brands, setBrands] = useState<AlgoliaSearchBrand[] | null>(null);
  const [loading, setLoading] = useState(false);
  const userToken = session?.user.member_id
    ? { userToken: session?.user.member_id }
    : {};

  const search = async (): Promise<
    MultipleQueriesResponse<unknown> | null | undefined
  > => {
    setLoading(true);

    const prefix = isGhostDomain ? "zz" : locale;
    try {
      return await Algolia.search([
        {
          indexName: `${prefix}_brands`,
          query: "",
          params: {
            hitsPerPage: 1000,
            filters: `is_deleted:false AND genders:${gender} AND brand_sale_type:${brandSaleType}`,
            ruleContexts: ["react_web_shop", "products_brands_data"],
            clickAnalytics: true,
            ...userToken,
          },
        },
        {
          indexName: `${prefix}_products`,
          query: "",
          params: {
            hitsPerPage: 0,
            maxValuesPerFacet: 1000,
            filters: `is_deleted:false AND genders:${gender}`,
            ruleContexts: ["react_web_shop", "products_brands_facets"],
            clickAnalytics: true,
            facets: ["brand.id"],
            ...userToken,
          },
        },
      ]);
    } catch (error) {
      console.error(`Error occured in algolia search request:`, error);
      return null;
    }
  };
  const onSuccess = (
    data: MultipleQueriesResponse<unknown> | undefined | null
  ) => {
    setLoading(false);
    if (data) {
      const { results } = data;
      const brandFacets = results[1]?.facets?.["brand.id"] || {};
      const brandsList = filter(
        results[0]?.hits as AlgoliaSearchBrand[],
        (f) => f.objectID in brandFacets
      );
      setBrands(brandsList);
    }
  };

  useAsync(search, onSuccess, 50);

  return {
    brands,
    loading,
  };
};
